import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'rebass'
import * as final from './styles'

const Final = ({
	status,
	handleToggleSupport
}: {
	status: 'success' | 'whatsapp'
	handleToggleSupport: () => void
}) => {
	const data = useStaticQuery(query)
	const { responseParagraph, responseTextButton } = data.saudeDigital.metadata.advice.response
	const { paragraph, textButton, link } = data.saudeDigital.metadata.advice.notResponse

	const whatsappMessage = <p dangerouslySetInnerHTML={{ __html: paragraph }} />

	const successMessage = <final.Paragraph dangerouslySetInnerHTML={{ __html: responseParagraph }} />

	return (
		<final.Container>
			<final.Description wrap fontSize='14px/16px'>
				{status === 'success' ? successMessage : whatsappMessage}
			</final.Description>
			<final.ButtonContainer>
				<final.Button
					id='btn-support-final'
					onClick={status === 'success' ? handleToggleSupport : () => false}
				>
					{status === 'success' ? (
						<final.Text>{responseTextButton}</final.Text>
					) : (
						<final.Ancora href={link} target='_blank'>
							{textButton}
						</final.Ancora>
					)}
				</final.Button>
			</final.ButtonContainer>
		</final.Container>
	)
}

export default Final

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "fleury" }) {
			metadata {
				advice: assessoria {
					response: resposta_util {
						responseTitle: titulo
						responseParagraph: paragrafo
						responseTextButton: botao
					}

					notResponse: resposta_nao_util {
						title: titulo
						paragraph: paragrafo
						textButton: botao
						link: link_atendimento
					}
				}
			}
		}
	}
`
