import React, { useContext } from 'react'

import IAppointment from '@cuidardigital/commons/types/IAppointment'

import { store } from '../../../stores/app'
import { Button, ButtonRed, Col, ComponentWrapper, Divider, Span } from './styles'

interface IPropsAppointments extends IAppointment {
	disabled?: boolean
	onStart: (args: {
		id: string
		link: string
		cpf: string
		holder: string
		email: string
		phone: string
		birthDay: string
		doctorEmail: string
		medicalEncounterId: string
	}) => () => void
	onCancel: (id: string) => () => void
	onNewUrl: (link: string, type: string) => () => void
	onCopy: (param: boolean) => void
	holder: string
	speciality: { group: string; subGroup: string }
}

const Support = ({ id, link, holder, date, hour, onCancel, speciality }: IPropsAppointments) => {
	// @ts-ignore
	const { state } = useContext(store)
	const { isDoctor } = state.session
	const handleClick = () => window.open(link, '_blank')
	return (
		<ComponentWrapper id='card-open-support' bg='gray'>
			<Col size='12' flex flexDirection='column'>
				<Span bold fontSize='32px/40px' color='white' wrap>
					{holder}
				</Span>
			</Col>
			<Col size='12'>
				<Divider />
			</Col>
			<Col size='12' flex flexDirection='column'>
				<Span bold fontSize='20px/20px' color='white'>
					Hora: {hour}
				</Span>
				<Span mb='8px' color='white'>
					Data: {date}
				</Span>
			</Col>
			<Col size='12'>
				<Span display='block' fontSize='20px/20px' color='white' bold>
					{speciality.group}
				</Span>
				<Span fontSize='20px/20px' color='white' wrap>
					{speciality.subGroup}
				</Span>
			</Col>
			<Col size='12' flex flexDirection='column'>
				{isDoctor && (
					<Button id='button-start-support' onClick={handleClick} m='0px 0px 10px'>
						Iniciar assessoria
					</Button>
				)}
				<ButtonRed id='button-cancel-support' onClick={onCancel(id)}>
					Cancelar agendamento
				</ButtonRed>
			</Col>
		</ComponentWrapper>
	)
}

export default Support
