import styled from 'styled-components'

import { Span } from '../../../superSetCommons'

export const Container = styled.div`
	padding: 30px 0;
	border-bottom: 1px solid #cccccc;
`

export const Header = styled.div`
	display: flex;
	padding: 8px 0 0 10px;
	width: 100%;
	flex-direction: row;
	align-items: center;
	margin-left: -20px;
`

export const Text = styled(Span)``
