import React from 'react'

import useState from '@cuidardigital/commons/hooks/useState'
import FinalSupport from '../final'
import Footer from '../Footer'

import { Icon, IconContainer, Label } from '../../styles'
import * as description from './styles'

const SupportDetails = ({
	params,
	handleAction
}: {
	handleAction: (status: boolean) => void
	params: { color: string; icon: string; label: string; description: string }
}) => (
	<>
		<description.Header>
			<IconContainer color={params.color}>
				<Icon src={params.icon} />
			</IconContainer>
			<Label small>{params.label}</Label>
		</description.Header>
		<description.Container>
			<description.Text
				wrap
				fontSize='14px/16px'
				dangerouslySetInnerHTML={{ __html: params.description }}
			/>
		</description.Container>
		<Footer handleAction={handleAction} />
	</>
)

const Description = ({
	params,
	handleSuccess,
	handleToggleSupport
}: {
	params: { color: string; icon: string; label: string; description: string }
	handleSuccess: (param: boolean) => void
	handleToggleSupport: () => void
}) => {
	const [state, handleState] = useState({
		step: 'description'
	})

	const handleFooterAction = (status: boolean) => {
		handleState({ status, step: 'result' })
		handleSuccess(status)
	}

	const renderNextStep = () => {
		switch (state.step) {
			case 'description':
				return <SupportDetails handleAction={handleFooterAction} params={params} />
			default:
				return (
					<FinalSupport
						handleToggleSupport={handleToggleSupport}
						status={state.status ? 'success' : 'whatsapp'}
					/>
				)
		}
	}

	return <>{renderNextStep()}</>
}

export default Description
