import moment from 'moment'
import React, { useEffect } from 'react'

import Loader from '@cuidardigital/commons/components/Loader/Loader'
import useState from '@cuidardigital/commons/hooks/useState'
import { getSchedules, scheduleAdvisor } from '@cuidardigital/commons/services/support'
import { dateToISO, dateToISOWithTime, days, months } from '@cuidardigital/commons/utils/date'
import MessageModal from '../../../Modal'
import Calendar from '../calendar'

import { Icon, Label } from '../../styles'
import { Title, WrapperContainer } from '../styles'
import { CalendarContainer, Header, HeaderContent, IconWrapper, ScheduleButton } from './styles'

const messageToShow = {
	title: 'Pedimos desculpas',
	message: 'Todo mundo erra, dessa vez foi nossa vez. Tente novamente em alguns instantes.',
	buttonText: 'Ok'
}

const Schedule = ({ data, getStep }) => {
	const [state, handleState] = useState({
		index: 'initial',
		step: null,
		dateSelected: null,
		timeSelected: null,
		monthSelected: {
			text: months[new Date().getMonth()],
			value: new Date().getMonth()
		},
		dates: [],
		loading: false
	})

	useEffect(() => {
		getAvailableMonths()
	}, [])

	const getAvailableMonths = () => {
		let availableMonths = months.filter((_, index) => index >= new Date().getMonth())
		availableMonths = availableMonths.map(month => {
			return {
				text: month,
				value: months.indexOf(month)
			}
		})
		handleState({ availableMonths })
		getAvailableDates(state.monthSelected)
	}

	const getAvailableDates = async month => {
		handleState({ loading: true })

		let startMonth

		const now = new Date()
		const year = now.getFullYear()
		const currentMonth = now.getMonth()

		if (month.value === currentMonth) {
			startMonth = now.getDate()
		}

		startMonth = `${startMonth || '01'}/${month.value + 1}/${year}`
		startMonth = moment(startMonth, 'DD/MM/YYYY').format('DD/MM/YYYY')
		const endMonth = `${moment().daysInMonth()}/${month.value + 1}/${year}`

		const response = await getSchedules(dateToISO(startMonth), dateToISO(endMonth))

		const group: any[] = response.filter(medicalGroup => {
			const medicalSpecialty = medicalGroup.speciality.group
			const medicalSpecialtySubGroup = medicalGroup.speciality.subGroup
			return medicalSpecialty === data.title && medicalSpecialtySubGroup === data.label
		})

		const [schedules] = group
		let availableSchedules = {}

		schedules.schedules.forEach(schedule => {
			const date = moment(schedule.date.slice(0, 10)).format('DD/MM/YYYY')

			let time = new Date(schedule.date)
			const hour = time.getHours()
			const minutes = time.getMinutes()

			time = `${hour}:${minutes === 0 ? '00' : minutes}`

			availableSchedules[date] = {
				date,
				times: availableSchedules[date]?.times
					? [...(availableSchedules[date]?.times || {}), time]
					: [time],
				duration: schedule.duration,
				dateNumber: moment(date, 'DD/MM/YYYY').date(),
				weekDay: days[moment(date, 'DD/MM/YYYY').day()],
				month: month.text,
				speciality: schedules.speciality._id,
				holder: schedules.holder
			}
		})

		availableSchedules = Object.values(availableSchedules).sort((a, b) => a.date - b.date)

		handleState({ dates: availableSchedules, loading: false })
	}

	const handleDateSelection = (date: number) => () => {
		handleState({ dateSelected: date, timeSelected: null })
	}

	const handleTimeSelection = (time: string) => () => {
		handleState({
			timeSelected: time,
			dateSelected: {
				...state.dateSelected,
				time,
				isoDate: dateToISOWithTime(state.dateSelected.date, time)
			}
		})
	}

	const handleMonthSelection = monthSelected => {
		handleState({ monthSelected, dateSelected: null })
		getAvailableDates(monthSelected)
	}

	const handleSchedule = async () => {
		handleState({ loading: true })
		const response = await scheduleAdvisor({
			speciality: state.dateSelected.speciality,
			holder: state.dateSelected.holder,
			scheduled_to: state.dateSelected.isoDate,
			duration: state.dateSelected.duration
		})

		if (response?.status === 201) {
			getStep({ ...data, ...state.dateSelected, value: 'success' })
		} else {
			handleState({ errorMessage: true })
		}

		handleState({ loading: false })
	}

	const handleResetDate = () => handleState({ dates: null, timeSelected: null })

	const handleErrorMessage = () => handleState({ showErrorMessage: !state.showErrorMessage })

	return (
		<WrapperContainer>
			{state.showErrorMessage && (
				<MessageModal
					maxWidth='450px'
					onClose={handleErrorMessage}
					title={messageToShow?.title}
					message={messageToShow?.message}
					buttonText={messageToShow?.buttonText}
				/>
			)}
			<Header>
				<Title wrap fontSize='20px/20px' bold>
					{data.title}
				</Title>
				<HeaderContent>
					<IconWrapper color={data.color}>
						<Icon src={data.icon} />
					</IconWrapper>
					<Label small>{data.label}</Label>
				</HeaderContent>
			</Header>
			{state.loading ? (
				<Loader />
			) : (
				<>
					<CalendarContainer>
						<Calendar
							handleSchedule={handleSchedule}
							resetDate={handleResetDate}
							getMonthSelection={handleMonthSelection}
							monthSelected={state.monthSelected}
							months={state.availableMonths}
							dateSelected={state.dateSelected}
							timeSelected={state.timeSelected}
							handleDate={handleDateSelection}
							handleTime={handleTimeSelection}
							data={state.dates}
						/>
					</CalendarContainer>
					{state.timeSelected && (
						<WrapperContainer>
							<ScheduleButton id='danone-advisor-date-schedule' onClick={handleSchedule}>
								Agendar
							</ScheduleButton>
						</WrapperContainer>
					)}
				</>
			)}
		</WrapperContainer>
	)
}

export default Schedule
