import styled from 'styled-components'

import {
	Col as CommonColumn,
	Grid as CommonGrid
} from '@cuidardigital/commons/components/Grid/grid'
import {
	Button as SuperButton,
	ButtonLink as SuperButtonLink,
	Span as SuperSpan,
	SpanDisabled,
	SpanHighlight
} from '../../superSetCommons'

export const Col = styled(CommonColumn)``
export const Grid = styled(CommonGrid)``

interface IWrapper {
	height?: string | number
	mt?: string | number
	bg?: string
}

export const Span = styled(SuperSpan)``

export const PatientLinkWrapper = styled(SpanHighlight)`
	cursor: pointer;
	text-align: left;
	-webkit-text-decoration: underline;
	text-decoration: underline;
	-webkit-letter-spacing: 0;
	-moz-letter-spacing: 0;
	-ms-letter-spacing: 0;
	letter-spacing: 0;
	width: min-content;
`

export const ButtonLink = styled(SuperButtonLink)`
	color: ${props => props.theme.colors.primary_hover};
`

export const ComponentWrapper = styled(CommonGrid)`
	// height: 260px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 10px #00000026;
	border-radius: 8px;
	opacity: 1;
	padding: 20px;
	padding-bottom: 10px;
	grid-row-gap: 0;
`

export const Divider = styled.hr`
	opacity: 0.2;
`

export const NewLink = styled.a`
	cursor: pointer;
	text-align: left;
	text-decoration: underline;
	font: Bold 14px/20px CentraleSans-Bold;
	letter-spacing: 0;
	color: ${props => props.theme.colors.primary_hover};

	@media (max-width: 1340px) {
		font: Bold 10px/20px CentraleSans-Bold;
	}

	@media (max-width: 768px) {
		font: Bold 8px/20px CentraleSans-Bold;
	}
`

export const Button = styled(SuperButton)`
	margin: 20px 0 0;
`

// closed
export const ButtonContainer = styled.div`
	padding-top: 7px;
	padding-bottom: 5px;
	max-width: 80%;
`
export const ClosedComponentWrapper = styled(CommonGrid)<IWrapper>`
	background: ${({ bg }) => bg || '#ffffff'} 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 10px #00000026;
	border-radius: 8px;
	opacity: 1;
	padding: 22px;
	grid-row-gap: 0;
`

export const EmailSpan = styled(SpanDisabled)`
	display: block;
	white-space: inherit;
	width: 100%;

	padding-bottom: 20px;
`

export const ClosedButton = styled(SuperButton)`
	border: 1px solid ${props => props.theme.colors.secundary_border};
	color: ${props => props.theme.colors.font_secundary};
	background: ${props => props.theme.colors.secundary};
	&:hover {
		background: ${props => props.theme.colors.secundary_hover};
	}
`
