import React, { useEffect, useRef } from 'react'

import useState from '@cuidardigital/commons/hooks/useState'

import { Icon } from '../../styles'
import { Text, WrapperContainer } from '../styles'
import {
	Badge,
	BadgeHeader,
	Button,
	DateText,
	DayText,
	Horizontal,
	HorizontalScroll,
	IconContainer,
	Month,
	MonthButton,
	MonthContainer,
	Months,
	NoContentContainer,
	NoContentText,
	TimesContainer,
	TimeText
} from './styles'

import arrowDown from '@cuidardigital/commons/assets/svg/arrow-down-icon.svg'
import arrowLeft from '@cuidardigital/commons/assets/svg/arrow-left-icon.svg'
import arrowRight from '@cuidardigital/commons/assets/svg/arrow-right-icon.svg'

const Calendar = ({
	data,
	months,
	handleDate,
	handleTime,
	monthSelected,
	dateSelected,
	timeSelected,
	resetDate,
	getMonthSelection
}: {
	data: any[]
	handleDate: (param: any) => void
	handleTime: (param: any) => void
	timeSelected: number
	dateSelected: any
}) => {
	const dayRef = useRef({ scrollLeft: 0 })
	const timeRef = useRef({ scrollLeft: 0 })

	const [state, handleState] = useState({
		showMonthSelection: false,
		showCalendar: false
	})

	useEffect(() => {
		if (data) {
			handleState({ showCalendar: true })
		}
	}, [data])

	const generateKey = () =>
		Math.random()
			.toString(36)
			.substring(2, 15)

	const handleShowMonthSelection = () => {
		resetDate()
		handleState({
			showMonthSelection: !state.showMonthSelection,
			showCalendar: !state.showCalendar
		})
	}

	const handleMonthSelection = monthSelection => () => {
		getMonthSelection(monthSelection)
		handleShowMonthSelection()
	}

	const handleScroll = (position: string, element: string) => () => {
		const elementRef = element === 'day' ? dayRef : timeRef
		if (elementRef.current) {
			if (position === 'left') {
				elementRef.current.scrollLeft -= 55
			} else {
				elementRef.current.scrollLeft += 55
			}
		}
	}

	return (
		<WrapperContainer>
			<Text>Escolha uma data entre as opções disponíveis</Text>
			<WrapperContainer>
				<MonthContainer>
					<Button id='danone-advisor-show-month-selection' onClick={handleShowMonthSelection}>
						<Text bold fontSize='14px/16px'>
							{monthSelected?.text || ''}
						</Text>
						<IconContainer>
							<Icon src={arrowDown} />
						</IconContainer>
					</Button>
				</MonthContainer>
			</WrapperContainer>
			{state.showCalendar && (
				<Horizontal>
					{data?.length > 0 ? (
						<>
							<IconContainer onClick={handleScroll('left', 'day')}>
								<Icon src={arrowLeft} />
							</IconContainer>
							<HorizontalScroll ref={dayRef}>
								{data?.map((date, index) => {
									return (
										<Badge
											id={`danone-advisor-date-select-${index}`}
											key={generateKey()}
											onClick={handleDate(date)}
											selected={dateSelected?.date === date.date}
										>
											<BadgeHeader>
												{date && !(typeof date === 'string') && (
													<DayText>{date.weekDay.slice(0, 3).toLowerCase()}</DayText>
												)}
												<DateText>{date.dateNumber}</DateText>
											</BadgeHeader>
										</Badge>
									)
								})}
							</HorizontalScroll>
							<IconContainer onClick={handleScroll('right', 'day')}>
								<Icon src={arrowRight} />
							</IconContainer>
						</>
					) : (
						<NoContentContainer>
							<NoContentText fontSize='14px/16px' wrap>
								Não há datas disponíveis neste mês.
							</NoContentText>
						</NoContentContainer>
					)}
				</Horizontal>
			)}
			{dateSelected && state.showCalendar && (
				<TimesContainer>
					<Text>Escolha um horário</Text>
					<Horizontal>
						<IconContainer onClick={handleScroll('left', 'time')}>
							<Icon src={arrowLeft} />
						</IconContainer>
						<HorizontalScroll ref={timeRef}>
							{dateSelected.times.map((time, index) => (
								<Badge
									thin
									key={generateKey()}
									id={`danone-advisor-time-select-${index}`}
									onClick={handleTime(time)}
									selected={timeSelected === time}
								>
									<TimeText>{time}h</TimeText>
								</Badge>
							))}
						</HorizontalScroll>
						<IconContainer onClick={handleScroll('right', 'time')}>
							<Icon src={arrowRight} />
						</IconContainer>
					</Horizontal>
				</TimesContainer>
			)}
			{state.showMonthSelection && months && (
				<Months>
					{months.map((month, index) => {
						return (
							<MonthButton
								edge={index === 0 || index === months.length - 1}
								id={`danone-advisor-select-month-${index}`}
								key={`danone-advisor-select-month-${index}`}
								onClick={handleMonthSelection(month)}
							>
								<Month wrap>{month.text}</Month>
							</MonthButton>
						)
					})}
				</Months>
			)}
		</WrapperContainer>
	)
}

export default Calendar
