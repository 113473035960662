import React, { useRef } from 'react'

import useState from '@cuidardigital/commons/hooks/useState'
import Menu from './menu'
import ScheduleAdvisor from './schedule'
import SuccessAdvisor from './success'

import {
	Arrow,
	BackContainer,
	Card,
	Cards,
	CloseContainer,
	Container,
	Content,
	Header,
	HeaderContent,
	Icon,
	IconSupport,
	Label,
	Subtitle,
	Title,
	Top
} from '../styles'

import close from '@cuidardigital/commons/assets/svg/close-icon.svg'
import backArrow from '@cuidardigital/commons/assets/svg/left-arrow-icon.svg'
import arrow from '@cuidardigital/commons/assets/svg/right-arrow-icon.svg'

import { graphql, useStaticQuery } from 'gatsby'

interface IAdvisor {
	color: string
	icon: string
	label: string
}

const InitialAdvisoryStep = ({ getStep: onGetStep }: { getStep: (param: IAdvisor) => any }) => {
	const getStep = value => () => onGetStep(value)
	const data = useStaticQuery(query)
	const advisors = data.saudeDigital.metadata.advice.danone.category
	const { subtitle } = data.saudeDigital.metadata.advice.danone

	return (
		<>
			<Top>
				<Subtitle fontSize='14px/16px' wrap>
					{subtitle}
				</Subtitle>
			</Top>
			<Cards>
				{advisors.map(advisor => {
					return (
						<Card
							id={`card-advisor-options-${advisor.value}`}
							key={`card-advisor-options-${advisor.value}`}
							onClick={getStep(advisor)}
						>
							<IconSupport src={advisor.icon} />
							<Label>{advisor.title}</Label>
							<Arrow src={arrow} />
						</Card>
					)
				})}
			</Cards>
		</>
	)
}

const Medical = ({
	handleToggleSupport,
	goBack
}: {
	handleToggleSupport: () => void
	goBack: () => void
}) => {
	const [state, handleState] = useState({
		step: {
			value: 'initial'
		}
	})

	const scheduleRef = useRef({})

	const getStep = step => {
		if (step.value === 'success') {
			scheduleRef.current = step
		}

		handleState({ step })
	}

	const handleBack = () => {
		const step = state.step.value

		switch (step) {
			case 'initial':
				return goBack()
			case 'danone':
				return handleState({ step: { value: 'initial' } })
			case 'schedule':
				return handleState({ step: { value: 'danone' } })
			case 'success':
				return handleState({
					step: { ...scheduleRef.current, value: 'schedule' }
				})
			default:
				return goBack()
		}
	}

	const renderAdvisorsSteps = () => {
		const step = state.step.value

		switch (step) {
			case 'initial':
				return <InitialAdvisoryStep getStep={getStep} />
			case 'danone':
				return <Menu getStep={getStep} />
			case 'schedule':
				return <ScheduleAdvisor data={state.step} getStep={getStep} />
			case 'success':
				return (
					<SuccessAdvisor
						meta={state.step}
						handleBack={handleBack}
						handleToggleSupport={handleToggleSupport}
					/>
				)
			default:
				return <InitialAdvisoryStep getStep={getStep} />
		}
	}
	const data = useStaticQuery(query)
	const { title_modal } = data.saudeDigital.metadata.advice.danone
	const { title } = data.saudeDigital.metadata.advice.response

	return (
		<Container>
			<Header>
				{state.step.value === 'success' ? (
					<HeaderContent end>
						<Title fontSize='24px/32px' success>
							{title}
						</Title>
					</HeaderContent>
				) : (
					<HeaderContent>
						<BackContainer id='advisor-back' onClick={handleBack}>
							<Icon src={backArrow} />
						</BackContainer>
						<Title fontSize='16px/20px'>{title_modal}</Title>
					</HeaderContent>
				)}
				<CloseContainer id='advisor-close' onClick={handleToggleSupport}>
					<Icon src={close} />
				</CloseContainer>
			</Header>
			<Content>{renderAdvisorsSteps()}</Content>
		</Container>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "fleury" }) {
			metadata {
				advice: assessoria {
					danone {
						category: categoria {
							value
							title: titulo
							icon: icone
						}

						title_modal: titulo
						subtitle: subtitulo
					}

					response: resposta_util {
						title: titulo
					}
				}
			}
		}
	}
`

export default Medical
