import styled, { css } from 'styled-components'
import { PrimaryButton as CommonButton, Span } from '../../../superSetCommons'

export const Header = styled.div`
	display: flex;
	align-items: center;
`

export const Button = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	border: none;
	background-color: transparent;
`

export const Horizontal = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

export const HorizontalScroll = styled.div`
	max-width: 85%;
	display: flex;
	overflow-x: hidden;
	overflow-y: hidden;
	padding: 15px 0;
	scroll-behavior: smooth;
`

export const Badge = styled.button<{
	selected: boolean
	thin?: boolean
}>`
	border: none;
	margin: 0 10px;
	color: ${props => (props.selected ? '#F2F2F2' : '#333')};
	border-radius: 8px;
	padding: 10px;
	background: ${props => (props.selected ? '#18C3F4' : '#F7F7F7')};
	${props =>
		!props.thin &&
		css`
			min-height: 65px;
		`}
`

export const BadgeHeader = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-around;
`

export const DayText = styled.span`
	font-weight: bold;
	font-size: 13px;
`

export const DateText = styled.span`
	font-size: 15px;
`

export const TimeText = styled(DateText)``

export const ScheduleButton = styled(CommonButton)``

export const MonthContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
`

export const IconContainer = styled.button`
	background: transparent;
	border: none;
	margin-left: 10px;
`

export const TimesContainer = styled.div`
	margin-top: 10px;
`

export const Months = styled.div`
	display: flex;
	background: #fff;
	flex-direction: column;
	max-height: 350px;
	width: 150px;
	padding: 10px 20px;
	box-shadow: 0 4px 8px 0 rgba(20, 20, 20, 0.3);
	border-radius: 5px;
	position: relative;
	bottom: 0px;
	left: 75px;
`

export const Month = styled(Span)<{}>`
	padding: 10px 0;
`

export const MonthButton = styled(Button)<{
	edge: boolean
}>`
	justify-content: center;
	${props =>
		!props.edge &&
		css`
			border-bottom: 1px solid #e5e5e5;
			border-top: 1px solid #e5e5e5;
		`}
`

export const NoContentContainer = styled.div`
	display: flex;
	height: 80px;
	width: 100%;
	align-items: center;
	justify-content: center;
`

export const NoContentText = styled(Span)`
	text-align: center;
`
