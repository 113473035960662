import styled from 'styled-components'

import { PrimaryButton as CommonButton, Span } from '../../../superSetCommons'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 270px;
`

export const Description = styled(Span)`
	margin: 10px 0 0 0;
`

export const ButtonContainer = styled.div``

export const Button = styled(CommonButton)`
	padding: 0;
`

export const Text = styled.span`
	font-weight: bold;
`

export const Ancora = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 42px;
	color: white;
	font-weight: bold;

	&:hover {
		color: white;
	}
`
export const Paragraph = styled.p`
	p {
		width: 88%;
		line-height: 2;
	}
`
