// @ts-ignore
import _filter from 'lodash/filter'
import _keys from 'lodash/keys'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import ShowModal from '@cuidardigital/commons/components/Modal'
import { create } from '@cuidardigital/commons/services/appointment'
import { maskCpfCnpj } from '@cuidardigital/commons/utils/mask'
import Checkbox from '@cuidardigital/commons/components/Checkbox'

import { dateToISOWithTime, validateDateNewConsulting } from '@cuidardigital/commons/utils/date'

import SemanticInput from '@cuidardigital/commons/components/Inputs/semantic'
import Select, { SelectAsync } from '@cuidardigital/commons/components/Select'
import { getPatient } from '@cuidardigital/commons/services/doctor.services'
import { verifyEligibility } from '@cuidardigital/commons/services/elegibility'
import { schedulingList } from '@cuidardigital/commons/utils/hourList'
import {
	formatDate,
	formatPhone,
	maskCard,
	unMaskCard,
	validateEmail
} from '@cuidardigital/commons/utils/masks'
import { graphql, useStaticQuery } from 'gatsby'
import Tooltip from '@cuidardigital/commons/components/Tooltip'
import iconSuccess from '@cuidardigital/commons/assets/svg/icon-success.svg'
import iconError from '@cuidardigital/commons/assets/svg/icon-error.svg'
import { Span } from '@cuidardigital/commons/components/Typography'
import theme from '../../../theme'
import { store } from '../../stores/app'
import { ButtonReturn, InputLabel } from '../superSetCommons'
import {
	IconContainer,
	Icon,
	DisplayFlex,
	FormWrapper,
	InputElement,
	InputWrapper,
	ModalSubtitle,
	ModalTitle,
	ModalWrapper,
	SubmitButton,
	InputResponse
} from './styles'

interface IOptions {
	name: string
	cpf?: string
	email?: string
}

const filterOptions = (inputValue: string, patients: any) => {
	return patients.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))
}

interface IMessage {
	title?: string
	message?: string
	buttonText?: string
}

interface IProps {
	setShowNewAppointment: (param: boolean) => void
	setLoading: (param: boolean) => void
	setMessageToShow: (message: IMessage) => void
	setShowMessage: (param: boolean) => void
}

interface IObjectLiteral {
	[key: string]: any
}

const eligibilityDefaultError = `O paciente não está elegível para a consulta por convênio. Desmarque a opção de elegibilidade para continuar o agendamento da consulta.`

const formInitialState = {
	name: '',
	email: '',
	telephone: '',
	date: moment().format('DD/MM/YYYY'),
	hour: null,
	code: null,
	cnpjs: [],
	insuranceCardNumber: ''
}

const eligibilityState = {
	loading: false,
	code: '',
	error: '',
	success: false
}

const NewAppointment: React.FC<IProps> = ({
	setShowNewAppointment,
	setLoading,
	setMessageToShow,
	setShowMessage
}) => {
	const data = useStaticQuery(query)

	const errorsInitialState = useMemo(
		() => ({
			...Object.keys(formInitialState).reduce((value, item) => ({ ...value, [item]: false }), {}),
			eligibility: false
		}),
		[]
	)
	const [formValues, setFormValues] = useState(formInitialState)
	const [eligibility, setEligibility] = useState(eligibilityState)
	const [errors, setErrors] = useState(errorsInitialState)
	const [formIsWrong, setFormIsWrong] = useState(true)
	const [optionsName, setOptionsName] = useState<IOptions[]>([])
	const [intervalTypeName, setIntervalTypeName] = useState()

	const debouncedVerifyEligibility = useDebouncedCallback(() => checkEligibility(), 1000)

	const {
		title,
		paragraph,
		field_patient,
		field_email,
		field_phone,
		field_date,
		field_hour,
		button_create,
		button_come_back
	} = data.saudeDigital.metadata.appointment

	// @ts-ignore
	const { state } = useContext(store)

	useEffect(() => {
		normalizerComponent()
	}, [])

	useEffect(() => {
		normalizerErrors()
	}, [errors])

	useEffect(() => {
		normalizerHours()
	}, [formValues.date])

	useEffect(() => {
		if (!formValues.name && optionsName[0]) {
			handleName(optionsName[0])
		}
	}, [optionsName])

	const checkEligibility = async () => {
		const { date, hour, insuranceCardNumber: patientMedicalPlanNumber } = formValues
		const appointmentDate = dateToISOWithTime(date, hour?.value || '')

		const { cpf: doctorCpf } = state.doctorCurrent

		const response = await verifyEligibility({
			doctorCpf,
			appointmentDate,
			patientMedicalPlanNumber: unMaskCard(patientMedicalPlanNumber)
		})

		if (response?.status === 200) {
			setEligibility(prev => ({ ...prev, code: response.data, loading: false, success: true }))
			setFormValues(prev => ({ ...prev, elegible: true }))
			setErrors(prev => ({ ...prev, elegible: false }))
		} else {
			let eligibilityError = response?.data?.attributes?.motivoGlosa?.descricao
			if (eligibilityError)
				eligibilityError = `${eligibilityDefaultError}. cód: ${response?.data?.attributes?.motivoGlosa?.codigo}`
			else
				eligibilityError = `${
					response?.message ? eligibilityDefaultError : 'Error interno do servidor'
				} .cód: ${response?.statusCode || '500'}`
			setEligibility(prev => ({ ...prev, error: eligibilityError, loading: false }))
			setErrors(prevErrors => ({ ...prevErrors, eligibility: true }))
		}
	}

	const handleFormSubmit = () => {
		try {
			const { date, hour, email, name, code, telephone } = formValues
			if (!name.name) throw new Error('Sem nome')
			setLoading(true)
			const appointmentDate = dateToISOWithTime(date, hour.value)

			const payload = {
				email,
				name: name.name,
				date: appointmentDate,
				duration: 60,
				code: code?.value,
				telephone
			}

			if (eligibility.code) {
				payload.eligibilityId = eligibility?.code?.toString()
			}

			create(payload, state)
				.then(response => {
					if (response) {
						if (response.status <= 204) {
							setMessageToShow({
								title: 'Consulta criada',
								message:
									'Agora o paciente precisa confirmar a consulta por e-mail ou SMS./n Após a confirmação, você receberá uma notificação pelos mesmos canais.',
								buttonText: 'Ok, entendi'
							})
							resetForm()
							setShowNewAppointment(false)
						} else {
							setMessageToShow({ message: response.data.message })
						}
					} else {
						setShowNewAppointment(false)
					}
				})
				.catch(e => {
					setMessageToShow({
						title: 'Pedimos desculpas',
						message: JSON.stringify(e),
						buttonText: 'Ok, entendi'
					})
				})
				.finally(() => {
					setShowMessage(true)
					setLoading(false)
				})
		} catch {
			setLoading(false)
			setMessageToShow({
				title: 'Pedimos desculpa',
				message:
					'Falhamos ao salvar algum dos campos preenchidos, poderia checar e tentar novamente?',
				buttonText: 'Ok, entendi'
			})
			setShowMessage(true)
		}
	}

	const formSubmit = () => {
		if (formIsWrong) {
			setMessageToShow({
				title: 'Ops',
				message: 'Formulário com valores inválidos',
				buttonText: 'Ok, entendi'
			})
			setShowMessage(true)
		} else {
			handleFormSubmit()
		}
	}

	const closeModal = () => {
		setShowNewAppointment(false)
	}

	const handleName = e => {
		const { value, email, label, telephone } = e
		const index = label.indexOf(' | ')
		let newName = label
		let formName = { name: value, value, email, ...e }
		if (index > -1) {
			newName = label.substr(0, index)
			formName = { ...formName, name: newName, value: newName, label: newName }
			setOptionsName([formName])
		}
		setFormValues({
			...formValues,
			name: formName,
			telephone: telephone || formValues.telephone,
			email
		})
		setErrors({ ...errors, name: false })
	}

	const handleNameFocus = () => {
		setFormValues({ ...formValues, name: null })
		setErrors({ ...errors, name: true })
		setOptionsName([])
	}

	const handleHour = e => {
		const { value, label } = e
		setFormValues({ ...formValues, hour: { value, label } })
		setErrors({ ...errors, hour: false })
	}

	const handleDate = e => {
		const { value } = e.target
		const fDate = formatDate(value)
		let strDate = ''
		if (fDate.length === 10) {
			const appointmentDate = new Date(
				`${fDate.substring(3, 5)}/${fDate.substring(0, 2)}/${fDate.substring(6, 10)}`
			)

			if (appointmentDate.toDateString() === new Date().toDateString()) {
				const currentDate = new Date().toTimeString()
				strDate = currentDate.substring(0, 5)
				let strHour = parseInt(currentDate.substring(0, 2))
				let strMinute = parseInt(currentDate.substring(3, 5))

				if (strMinute < 15) {
					strMinute = 15
					strDate = `${currentDate.substring(0, 3)}${strMinute}`
				} else if (strMinute < 30) {
					strMinute = 30
					strDate = `${currentDate.substring(0, 3)}${strMinute}`
				} else if (strMinute < 45) {
					strMinute = 45
					strDate = `${currentDate.substring(0, 3)}${strMinute}`
				} else {
					strMinute = 0

					if (strHour < 23) {
						strHour += 1
						const pad = '00'
						// @ts-ignore
						strDate = `${pad.substring(0, pad.length - strHour.length) + strHour}:00`
					} else {
						strDate = '00:00'
					}
				}
			}
		}
		const hour = strDate ? { value: strDate, label: strDate } : formValues.hour
		setFormValues({ ...formValues, date: fDate, hour })
		setErrors({ ...errors, date: !validateDateNewConsulting(fDate) })
	}

	const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { email } = formValues
		if (email !== value) {
			setFormValues({ ...formValues, email: value })
			setErrors({ ...errors, email: validateEmail(value) })
		}
	}

	const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, label } = e
		setFormValues({ ...formValues, code: { value, label } })
		setErrors({ ...errors, code: false })
	}

	const handleTelephone = e => {
		const { value } = e.target
		const formattedValue = formatPhone(value)
		setFormValues({ ...formValues, telephone: formattedValue })
		setErrors({ ...errors, telephone: formattedValue.length > 14 && formattedValue.length < 15 })
	}

	const handleHealthInsuranceCard = (event: React.ChangeEvent<HTMLInputElement>) => {
		const insuranceCardNumber = unMaskCard(event.target.value)

		if (insuranceCardNumber.length >= 15) {
			setEligibility(prev => ({ ...prev, loading: true }))
			// debounces 1000ms server request
			debouncedVerifyEligibility.callback()
		}

		setEligibility(prev => ({ ...prev, success: false }))

		setFormValues({
			...formValues,
			insuranceCardNumber: maskCard(event.target.value),
			elegible: false
		})
		setErrors({ ...errors, eligibility: false })
	}

	const handleTelephoneBlur = () => {
		const { telephone } = formValues
		setErrors({ ...errors, telephone: telephone.length !== 15 })
	}

	const handleAgreeConsent = (param: boolean) => {
		setFormValues({ ...formValues, agreeConsent: param, insuranceCardNumber: '' })
		setErrors({ ...errors, eligibility: false })
	}

	const normalizerComponent = () => {
		const { isDoctor } = state.session
		if (isDoctor) {
			const { doctorCurrent } = state
			if (doctorCurrent.cnpjs) {
				setFormValues(prevFormValues => ({
					...prevFormValues,
					cnpjs:
						typeof doctorCurrent === 'object' && _keys(doctorCurrent).length > 0
							? doctorCurrent.cnpjs.map((cnpj: string) => ({
									value: cnpj,
									label: `CNPJ - ${maskCpfCnpj(cnpj)}`
									// tslint:disable-next-line: indent
							  }))
							: []
				}))
			}
		} else {
			const doctorCurrentCnpjs = state.doctorCurrent.cnpjs || []
			setFormValues(prevFormValues => ({
				...prevFormValues,
				cnpjs: doctorCurrentCnpjs.map((cnpj: string) => ({
					value: cnpj,
					label: `CNPJ - ${maskCpfCnpj(cnpj)}`
				}))
			}))
		}
	}

	const normalizerErrors = () => {
		const errorsForm = { ...errors }
		const values = { ...formValues }

		if (!formValues.agreeConsent) {
			delete values.elegible
			delete values.insuranceCardNumber
		}
		delete values.agreeConsent

		if (!formValues.cnpjs.length) {
			delete values.code
			// @ts-ignore
			errorsForm.code = false
		}
		const wrong = Object.values(errorsForm).some(b => b) || Object.values(values).some(v => !v)

		setFormIsWrong(wrong)
	}

	const normalizerHours = (hours?: IObjectLiteral[]) => {
		const today = moment(new Date(), 'DD/MM/YYYY')
		const tomorrow = moment()
			.add(1, 'days')
			.format('DD/MM/YYYY')
		const isTomorrow = tomorrow === formValues.date
		const insertedDate = moment(formValues.date, 'DD/MM/YYYY')
		const dateDiff = insertedDate.diff(today, 'days')
		if (dateDiff > 0 || isTomorrow) {
			return hours
		}
		const availableHours = _filter(hours, hour => {
			const timeMoment = moment(hour.value, 'HH:mm')
			const _hour = moment()
			return timeMoment.diff(_hour, 'minutes') > 0
		})
		return availableHours
	}

	const resetForm = () => {
		setFormValues(formInitialState)
	}

	const loadOptions = (inputValue, callback) => {
		clearTimeout(intervalTypeName)
		if (inputValue.length > 4) fetchPatient(inputValue, callback)
	}

	const fetchPatient = (inputValue, callback) => {
		setIntervalTypeName(
			setTimeout(
				() =>
					getPatient({ doctorCpf: state.doctorCurrent.cpf || state.session.cpf }).then(
						(response: any) => {
							const patients = [
								{ name: inputValue, cpf: inputValue, email: '', telephone: '' },
								...(response.data || [])
							].map((item: any) => ({
								...item,
								label: `${item.name} | ${item.email || 'Novo cadastro'} `,
								value: item.cpf,
								telephone: item.phone
							}))
							setOptionsName(patients)
							setFormValues({
								...formValues,
								name: { value: inputValue, label: inputValue, name: inputValue }
							})
							setErrors({ ...errors, name: inputValue.length === 0 })
							callback(filterOptions(inputValue, patients))
						}
					),
				800
			)
		)
	}

	return (
		<ShowModal mdMaxWidth='600px' xsMaxWidth='90vw' onClose={closeModal}>
			<ModalWrapper>
				<ModalTitle wrap bold fontSize='32px/40px'>
					{title}
				</ModalTitle>
				<ModalSubtitle wrap fontSize='16px/24px'>
					{paragraph}
				</ModalSubtitle>
				<FormWrapper>
					{/* @ts-ignore */}
					<InputWrapper>
						<InputLabel fontSize='14px/24px'>{field_patient}</InputLabel>
						{/* @ts-ignore */}
						<SelectAsync
							id='new-appointment-name'
							placeholder='Nome completo'
							loadOptions={loadOptions}
							onChange={handleName}
							onFocus={handleNameFocus}
							value={formValues.name}
							error={errors.name}
							maxMenuHeight={200}
							inputId='new-appointment-name-input'
						/>
						{errors.name && <InputResponse fontSize='12px/18px'>Nome é obrigatório</InputResponse>}
					</InputWrapper>
					<DisplayFlex>
						<InputWrapper width='calc(50% - 12px)'>
							<InputLabel fontSize='14px/24px'>{field_email}</InputLabel>
							<InputElement
								withAllBorder
								p='2px 10px'
								id='new-appointment-email'
								placeholder='paciente@email.com.br'
								value={formValues.email}
								onChange={handleEmail}
								error={errors.email}
							/>
							{errors.email && <InputResponse fontSize='12px/18px'>E-mail inválido</InputResponse>}
						</InputWrapper>
						<InputWrapper width='calc(50% - 12px)' margin='14px 0 0 24px'>
							<InputLabel fontSize='14px/24px'>{field_phone}</InputLabel>
							<InputElement
								withAllBorder
								p='2px 10px'
								id='new-appointment-telephone'
								placeholder='(00) 00000-0000'
								value={formValues.telephone}
								onChange={handleTelephone}
								onBlur={handleTelephoneBlur}
								error={errors.telephone}
							/>
							{errors.telephone && (
								<InputResponse fontSize='12px/18px'>Telefone inválido</InputResponse>
							)}
						</InputWrapper>
					</DisplayFlex>
					<DisplayFlex>
						<InputWrapper width='calc(50% - 12px)'>
							<InputLabel fontSize='14px/24px'>{field_date}</InputLabel>
							<InputElement
								withAllBorder
								p='2px 10px'
								id='new-appointment-date'
								placeholder='dd/mm/aaaa'
								value={formValues.date}
								onChange={handleDate}
								error={errors.date}
							/>
							{errors.date && <InputResponse fontSize='12px/18px'>Data inválida</InputResponse>}
						</InputWrapper>
						<InputWrapper width='calc(50% - 12px)' margin='14px 0 0 24px'>
							<InputLabel fontSize='14px/24px'>{field_hour}</InputLabel>
							<Select
								id='new-appointment-hour'
								options={normalizerHours(schedulingList)}
								placeholder='Escolha'
								value={formValues.hour}
								onChange={handleHour}
								maxMenuHeight={150}
								inputId='new-appointment-hour'
							/>
							{errors.hour && <InputResponse fontSize='12px/18px'>Horário inválido</InputResponse>}
						</InputWrapper>
					</DisplayFlex>
					{formValues.cnpjs.length > 0 && (
						<InputWrapper>
							<InputLabel fontSize='14px/24px'>Código do prestador</InputLabel>
							<Select
								id='new-appointment-code'
								className='new-appointment-code'
								options={formValues.cnpjs}
								placeholder='Escolha'
								value={formValues.code}
								onChange={handleCode}
								maxMenuHeight={95}
								inputId='new-appointment-code'
							/>
							{errors.code && <InputResponse fontSize='12px/18px'>Escolha um CNPJ</InputResponse>}
						</InputWrapper>
					)}
					{state.doctorCurrent?.healthInsurance && state.doctorCurrent?.healthInsurance.length > 0 && (
						<>
							<DisplayFlex>
								<Checkbox
									disabled={!formValues.hour}
									colors={{ background: theme.colors.black, icon: theme.colors.white }}
									action={handleAgreeConsent}
								/>
								<Span fontSize='14px/16px' wrap padding='15px 0 0 0'>
									Deseja validar a elegibilidade para o convênio Bradesco Saúde/Mediservice?
								</Span>
								<Tooltip
									flex
									place='bottom'
									id='eligbility-tooltip'
									content='Antes de validar a elegibilidade do paciente, informe a data e hora da consulta.'
								/>
							</DisplayFlex>
							<DisplayFlex>
								{formValues.agreeConsent && (
									<InputWrapper width='calc(50% - 12px)'>
										<InputLabel fontSize='14px/24px'>Número da Carteirinha</InputLabel>
										<SemanticInput
											p='2px 10px'
											bg='gray10'
											withLoading
											withBorder
											id='eligibility-input-card-number'
											placeholder='0000 0000 0000 0000'
											value={formValues.insuranceCardNumber}
											onChange={handleHealthInsuranceCard}
											input={InputElement}
											loading={eligibility.loading}
										/>
										{errors?.eligibility && (
											<IconContainer>
												<Icon src={iconError} />
												<InputResponse fontSize='12px/20px'>{eligibility.error}</InputResponse>
											</IconContainer>
										)}
										{eligibility.success && (
											<IconContainer>
												<Icon src={iconSuccess} />
												<InputResponse success fontSize='12px/20px'>
													O paciente está elegível para a consulta por convênio.
												</InputResponse>
											</IconContainer>
										)}
									</InputWrapper>
								)}
							</DisplayFlex>
						</>
					)}
					<DisplayFlex>
						<ButtonReturn id='button-back-appointment' onClick={closeModal}>
							{button_come_back}
						</ButtonReturn>
						<SubmitButton
							id='button-create-appointment'
							onClick={formSubmit}
							disabled={formIsWrong}
							formIsWrong={formIsWrong}
						>
							{button_create}
						</SubmitButton>
					</DisplayFlex>
				</FormWrapper>
			</ModalWrapper>
		</ShowModal>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "fleury" }) {
			metadata {
				appointment: modal_agendamento {
					title: titulo
					paragraph: paragrafo
					field_patient: campo_paciente
					field_email: campo_email
					field_phone: campo_telefone
					field_date: campo_data
					field_hour: campo_horario
					button_come_back: botao_voltar
					button_create: botao_criar_consulta
				}
			}
		}
	}
`

export default NewAppointment
