import CopyClipboard from '@cuidardigital/commons/components/copyClipboard'
import IAppointment from '@cuidardigital/commons/types/IAppointment'
import React, { useContext } from 'react'
import hourIcon from '@cuidardigital/commons/assets/svg/icon-hour.svg'
import smsIcon from '@cuidardigital/commons/assets/svg/icon-sms.svg'
import emailIcon from '@cuidardigital/commons/assets/svg/icon-email.svg'
import linkIcon from '@cuidardigital/commons/assets/svg/icon-link-blue.svg'
// import whatsappIcon from '@cuidardigital/commons/assets/svg/icon-whatsapp.svg'
import { keyGenerator } from '@cuidardigital/commons/utils/keys'
import { store } from '../../../stores/app'
import { Button as CommonButton, Span, ButtonLink } from '../styles'

import { Row, Column, WrapperContainer, DividerWrapper, Divider, Badge, Button } from './styles'

const links = [
	{ type: 'sms', icon: smsIcon, label: 'SMS' },
	{ type: 'email', icon: emailIcon, label: 'Email' },
	// { type: 'whatsapp', icon: whatsappIcon, label: 'Whatsapp' },
]

interface IPropsAppointments extends IAppointment {
	disabled?: boolean
	onStart: (args: {
		consultationId: string
		link: string
		cpf: string
		name: string
		email: string
		phone: string
		birthDay: string
		doctorEmail: string
		medicalEncounterId: string
	}) => () => void
	onCancel: (id: string) => () => void
	onNewUrl: (id: string, type: string) => () => void
	onCopy: (b: boolean) => void
}

const Appointment = ({
	eligibilityId,
	consultationId,
	link,
	disabled,
	name,
	email,
	date,
	hour,
	onStart,
	onCancel,
	onNewUrl,
	status,
	phone,
	birthDay,
	cpf,
	doctorEmail,
	patientLink,
	onCopy,
	patientPhone,
	...props
}: IPropsAppointments) => {
	// @ts-ignore
	const { state } = useContext(store)
	const { isDoctor } = state.session
	return (
		<WrapperContainer id='card-open-consultation'>
			<Column mb={5}>
				<Span bold fontSize='20px/20px'>
					{name}
				</Span>
			</Column>
			<DividerWrapper>
				<Divider />
			</DividerWrapper>
			<Column mt={15} mb={15}>
				<Row>
					<img alt='icon' src={hourIcon} />
					<Column ml={10} justifyCenter>
						<Span bold color='gray'>
							Horário
						</Span>
						<Span bold>{hour.replace(':', 'h')}</Span>
					</Column>
				</Row>
				<Column mt={10} mb={10}>
					<Span bold color='gray'>
						Envie o link da consulta para {name}
					</Span>
				</Column>
				{status === 'CREATED' && <Span mb='8px'>Pendente de confirmação</Span>}
				{status === 'CONFIRMED' && (
					<Row>
						{links.map(link => (
							<Button
								key={keyGenerator()}
								onClick={onNewUrl(consultationId, link.type)}
								id={`button-Resend-${link.type}`}
							>
								<Badge>
									<img alt='link-icon' src={link.icon} />
									<Span bold margin='0 5px'>
										{link.label}
									</Span>
								</Badge>
							</Button>
						))}
					</Row>
				)}
			</Column>
			<Row mb={10} mt={5}>
				<Button id='button-copy-link'>
					<Row>
						<img alt='copy-appointment-link-icon' src={linkIcon} />
						<CopyClipboard onCopy={onCopy} patientLink={patientLink}>
							<Span margin='2px 0 0 5px' bold color='blue'>
								Copiar link da consulta
							</Span>
						</CopyClipboard>
					</Row>
				</Button>
			</Row>
			<Column mt={10}>
				{isDoctor && status === 'CONFIRMED' && (
					<CommonButton
						id='button-start-appointment'
						onClick={onStart({
							consultationId,
							eligibilityId,
							link,
							cpf,
							name,
							email,
							phone,
							birthDay,
							doctorEmail,
							...props,
						})}
						disabled={disabled}
					>
						Iniciar consulta
					</CommonButton>
				)}
				<ButtonLink
					id='button-cancel-appointment'
					onClick={onCancel(consultationId)}
					disabled={disabled}
				>
					Cancelar consulta
				</ButtonLink>
			</Column>
		</WrapperContainer>
	)
}

export default Appointment
