import React from 'react'

import ShowModal from '@cuidardigital/commons/components/Modal'
import { deleteAppointment } from '@cuidardigital/commons/services/appointment'
import { Button, ButtonReturn } from '../superSetCommons'

import { ModalSubtitle, ModalTitle, ModalWrapper } from '../Modal/messageModal'

import { SubmitButton } from './styles'

interface ObjectLiteral {
	[key: string]: any
}

interface IMessage {
	title?: string
	message?: string
	buttonText?: string
}

interface IProps {
	setShowCancelAppointment: () => void
	appointmentToCancel: string
	setLoading: (param: boolean) => void
	setMessageToShow: (param: IMessage) => void
	setShowMessage: (param: boolean) => void
	state: ObjectLiteral
}

const CancelAppointment: React.FC<IProps> = ({
	setShowCancelAppointment,
	appointmentToCancel,
	setLoading,
	setMessageToShow,
	setShowMessage,
	state
}) => {
	const closeModal = () => {
		setShowCancelAppointment()
	}

	const cancelSubmit = () => {
		setLoading(true)
		deleteAppointment(appointmentToCancel, state)
			.then(response => {
				if (response.status === 204) {
					setMessageToShow({
						title: 'Você finalizou a consulta',
						message:
							'Para agendar outra, acesse a página inicial e clique em "Nova consulta". Obrigado e até a próxima.',
						buttonText: 'Ok, entendi'
					})
					setShowCancelAppointment(false)
				} else {
					setMessageToShow({ message: response.data.message })
				}
			})
			.catch(e => {
				setMessageToShow({
					message: `Não foi possível realizar sua solicitação. ${JSON.stringify(e)}`
				})
			})
			.finally(() => {
				setShowMessage(true)
				setLoading(false)
			})
	}

	return (
		<ShowModal maxWidth='420px' padTop='200px' onClose={closeModal}>
			<ModalWrapper>
				<ModalTitle wrap bold fontSize='32px/40px'>
					Cancelar consulta?
				</ModalTitle>
				<ModalSubtitle wrap fontSize='16px/24px'>
					Para agendar outra consulta, acesse a página inicial e clique em "Nova consulta".
				</ModalSubtitle>

				<ButtonReturn id='button-appointment-back' onClick={closeModal}>
					Voltar
				</ButtonReturn>
				<SubmitButton id='button-appointment-cancel' onClick={cancelSubmit}>
					Cancelar consulta
				</SubmitButton>
			</ModalWrapper>
		</ShowModal>
	)
}

export default CancelAppointment
