import React from 'react'

import * as success from './styles'

import { graphql, useStaticQuery } from 'gatsby'

interface IMeta {
	title: string
	label: string
	date: string
}

const SuccessAdvisor = ({
	handleToggleSupport,
	meta
}: {
	handleToggleSupport: () => void
	meta: IMeta
}) => {
	const data = useStaticQuery(query)
	const {
		description,
		button_sucess
	} = data.saudeDigital.metadata.advice.danone.schedule_advice.response

	return (
		<success.Container>
			<success.Description dangerouslySetInnerHTML={{ __html: description }} />
			<success.MetaData>
				<success.MetaTitle bold fontSize='16px/20px'>
					{meta.title}
				</success.MetaTitle>
				<success.MetaDescription fontSize='12px/14px'>
					Paciente com {meta.label}
				</success.MetaDescription>
				<success.MetaDescription fontSize='12px/14px'>{`${meta.weekDay}, ${meta.date.slice(
					0,
					2
				)} de ${meta.month}, ${meta.time}h`}</success.MetaDescription>
			</success.MetaData>
			<success.ButtonContainer>
				<success.Button id='danone-advisor-end-schedule' onClick={handleToggleSupport}>
					<success.Text>{button_sucess}</success.Text>
				</success.Button>
				{/* <success.Button cancel id="danone-advisor-change-schedule" onClick={handleBack}>
					<success.Text>Alterar agendamento</success.Text>
				</success.Button> */}
			</success.ButtonContainer>
		</success.Container>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "fleury" }) {
			metadata {
				advice: assessoria {
					danone {
						schedule_advice: agendar_assessoria {
							response: resposta_util {
								title: titulo
								description: paragrafo
								button_sucess: botao_sucesso
							}
						}
					}
				}
			}
		}
	}
`

export default SuccessAdvisor
