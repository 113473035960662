import styled from 'styled-components'

import { Col, Grid } from '@cuidardigital/commons/components/Grid/grid'
import { Button, ButtonLink, Span } from '../superSetCommons'

export { Col, Grid, Button, Span, ButtonLink }
interface IWrapper {
	height?: string | number
	mt?: string | number
}

export const ComponentWrapper = styled(Grid)`
	min-height: 85vh;
	background: #f7f7f7 0% 0% no-repeat padding-box;
	width: 100%;
	box-shadow: 0px 0px 10px #00000026;
	border-radius: 8px;
	opacity: 1;
	margin-top: ${({ mt }: IWrapper) => mt + 'px'};
	@media (max-width: 480px) {
		padding-bottom: 40px;
	}
`

export const NoContentGrid = styled(Grid)`
					// margin: 38px 0 0;

					@media (max-width: 480px) {
						margin: 0 10px;
						flex-flow: column;
					}
				`

export const OldAppointmentTitle = styled(Span)`
	white-space: inherit;
`

export const IconCalendar = styled.img`
	vertical-align: bottom;
`

export const SidebarContainer = styled(Col)`
	max-height: 90vh;
	overflow-y: auto;
	padding-top: 38px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 10px #00000026;

	// display: block;
	@media (max-width: 480px) {
		display: ${props => (props.mobileActive ? 'block' : 'none')};
		width: 100vw;
		min-width: 100vw;
		margin: 0;
	}
`

export const ContentWrapper = styled(Col)`
	padding: 2vw 1vw 3vw 1vw;
	max-height: 90vh;
	overflow-y: auto;

	@media (max-width: 480px) {
		display: ${props => (props.mobileActive ? 'block' : 'none')};
		width: 100vw;
		margin: 0;
	}
`

export const SupportContainer = styled.div`
	position: fixed;
	right: 0;
	bottom: 20%;
	margin-right: 50px;
`

export const SupportButton = styled.button<{
	showSupport: boolean
}>`
	position: fixed;
	right: 0;
	bottom: 5%;
	margin-right: 50px;
	background: ${props => (props.showSupport ? '#18C3F4' : '#748A96')};
	padding: 13px 20px;
	align-items: center;
	border-radius: 25px;
	border: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	&:hover {
		background: #18c3f4;
	}
`

export const SupportText = styled(Span)`
	margin-left: 10px;
	color: #fff;
`

export const Icon = styled.img``

export const ColButton = styled(Col)`
	@media (max-width: 480px) {
		padding-bottom: 2vw;
		grid-row: 1;
		button {
			width: 100%;
		}
	}
`
