import React, { useRef } from 'react'

import useState from '@cuidardigital/commons/hooks/useState'
import { graphql, useStaticQuery } from 'gatsby'
import {
	Arrow,
	BackContainer,
	Card,
	Cards,
	CloseContainer,
	Container,
	Content,
	Header,
	HeaderContent,
	Icon,
	IconSupport,
	Label,
	Subtitle,
	Title,
	TitleTech,
	Top
} from '../styles'
import Description from './description'
import FinalSupport from './final'
import VideoSupport from './video'

import close from '@cuidardigital/commons/assets/svg/close-icon.svg'
import backArrow from '@cuidardigital/commons/assets/svg/left-arrow-icon.svg'
import arrow from '@cuidardigital/commons/assets/svg/right-arrow-icon.svg'
import { keyGenerator } from '@cuidardigital/commons/utils/keys'

interface IStepData {
	color: string
	icon: string
	label: string
}

interface ISupport {
	categoria: string
	description: string
	icon: string
	id: string
	label: string
	value: string
	color: string
}

interface IStep extends IStepData {
	value: string | null
}

const InitialTechStep = ({
	handleAdvance,
	handleSuccess
}: {
	handleAdvance: (step: IStep) => void
	handleSuccess: (param: boolean) => void
}) => {
	const data = useStaticQuery(query)
	const { subtitle_tec } = data.saudeDigital.metadata.advice
	const supports = data.saudeDigital.metadata.advice.subcategory

	return (
		<>
			<Top>
				<Subtitle fontSize='14px/16px' wrap>
					{subtitle_tec}
				</Subtitle>
			</Top>
			<Cards>
				{supports.map((support: ISupport) => (
					<Card
						id={support.id}
						key={keyGenerator()}
						onClick={
							support?.value === 'whatsapp' ? () => handleSuccess(false) : handleAdvance(support)
						}
					>
						<IconSupport src={support.icon} />
						<Label>{support.label}</Label>
						<Arrow src={arrow} />
					</Card>
				))}
			</Cards>
		</>
	)
}

const Tech = ({
	handleToggleSupport,
	goBack
}: {
	handleToggleSupport: () => void
	goBack: () => void
}) => {
	const [state, handleState] = useState({
		step: {
			value: null
		}
	})

	const stepRef = useRef<IStep>()

	const handleAdvance = (step: IStep) => () => {
		stepRef.current = step
		handleState({ step })
	}

	const handleBack = () => {
		const step = state.step.value

		if (step === 'initial' || step === null) {
			return goBack()
		}

		if (step === 'videoDescription') {
			return handleState({ step: { value: 'videoMenu' } })
		}

		if (step === 'whatsapp') {
			return goBack()
		}

		return handleState({ step: { value: null } })
	}

	const handleSuccess = (status: boolean) => {
		if (!status) {
			stepRef.current = state.step
		}
		handleState({
			success: status,
			step: { value: !status ? 'whatsapp' : 'success' }
		})
	}

	const renderTechSteps = () => {
		const step = state.step.value

		if (step === 'initial' || step === null) {
			return <InitialTechStep handleSuccess={handleSuccess} handleAdvance={handleAdvance} />
		}

		if (step === 'videoMenu') {
			return (
				<VideoSupport
					router={{
						advance: param => handleState({ step: { ...param, value: 'videoDescription' } })
					}}
				/>
			)
		}

		if (step === 'whatsapp') {
			return <FinalSupport handleToggleSupport={handleToggleSupport} status='whatsapp' />
		}

		return (
			<Description
				handleToggleSupport={handleToggleSupport}
				handleSuccess={handleSuccess}
				params={state.step}
			/>
		)
	}

	return (
		<Container>
			<Header>
				{state.success ? (
					<HeaderContent success>
						<TitleTech fontSize='24px/24px' success>
							Sucesso!
						</TitleTech>
					</HeaderContent>
				) : (
					<HeaderContent>
						<BackContainer id='support-back' onClick={handleBack}>
							<Icon src={backArrow} />
						</BackContainer>
						<TitleTech fontSize='16px/20px'>
							{state.step.value === 'whatsapp' ? 'Atendimento por Whatsapp' : 'Problemas Técnicos'}
						</TitleTech>
					</HeaderContent>
				)}
				<CloseContainer id='support-close' onClick={handleToggleSupport}>
					<Icon src={close} />
				</CloseContainer>
			</Header>
			<Content>{renderTechSteps()}</Content>
		</Container>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				advice: assessoria {
					subtitle_tec: subtitulo_problemas_tecnicos
					category: categoria {
						description: paragrafo
					}

					subcategory: subcategoria {
						categoria
						id
						value
						icon: icone
						label: titulo
						description: paragrafo
					}
				}
			}
		}
	}
`

export default Tech
