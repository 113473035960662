import React from 'react'

import IConsultationClosed from '@cuidardigital/commons/types/IConsultationClosed'

import { graphql, useStaticQuery } from 'gatsby'
import {
	ButtonContainer,
	ClosedButton,
	ClosedComponentWrapper,
	Col,
	EmailSpan,
	Span
} from './styles'
interface IPropsConsultationClosed extends IConsultationClosed {
	bg?: string
	isDoctor: boolean | undefined
	onShowInfo: (args: {
		consultationId: string
		cpf: string
		name: string
		email: string
		phone: string
		birthDay: string
		doctorEmail: string
		link: string
	}) => () => void
}

const ConsultationClosed = ({
	consultationId,
	bg,
	name,
	email,
	date,
	hour,
	onShowInfo,
	cpf,
	phone,
	birthDay,
	doctorEmail,
	link,
	isDoctor,
	...otherProps
}: IPropsConsultationClosed) => {
	const data = useStaticQuery(query)
	const { consultation, patientInfo } = data.saudeDigital.metadata.dashboard
	return (
		<>
			<ClosedComponentWrapper {...otherProps} id='card-closed-consultation' bg={bg}>
				<Col size='12'>
					<Span bold fontSize='20px/20px' wrap>
						{name}
					</Span>
				</Col>
				<Col size='12' hd='12' tablet='9' pBigTablet='0px'>
					<EmailSpan fontSize='16px/20px' fontsm='8px' wrap>
						{email}
					</EmailSpan>
				</Col>
				<Col size='12' hd='12' tablet='9'>
					<Span wrap bold fontsm='8px' wrap>
						{consultation} {date} - {hour}
					</Span>
				</Col>
				<br />
				{isDoctor && (
					<Col size='12' hd='12' tablet='9'>
						<ButtonContainer>
							<ClosedButton
								id='button-patient-data'
								onClick={onShowInfo({
									consultationId,
									cpf,
									name,
									email,
									phone,
									birthDay,
									doctorEmail,
									link
								})}
							>
								<Span wrap fontSize='16px/20px' bold color='font_secundary'>
									{patientInfo}
								</Span>
							</ClosedButton>
						</ButtonContainer>
					</Col>
				)}
			</ClosedComponentWrapper>
		</>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				dashboard {
					consultation: consulta
					patientInfo: dados_do_paciente
				}
			}
		}
	}
`

export default ConsultationClosed
