import React from 'react'

import { Arrow, Card, Icon, IconSupport, Label } from '../../styles'
import { Text, Title, WrapperContainer } from '../styles'
import { Content, MenuContainer } from './styles'

import arrow from '@cuidardigital/commons/assets/svg/right-arrow-icon.svg'

import { graphql, useStaticQuery } from 'gatsby'

interface IStep {
	type: string
	label: string
	icon: string
	color: string
	step?: string
	value: string
	description: string
}

const MedicalMenu = ({ getStep: onGetStep }: { getStep: (param: IStep) => void }) => {
	const getStep = value => () => onGetStep(value)
	const data = useStaticQuery(query)
	const subcategories = data.saudeDigital.metadata.advice.danone.schedule_advice.subcategory
	const { paragraph } = data.saudeDigital.metadata.advice.danone

	return (
		<WrapperContainer>
			<Text fontSize='14px/16px' wrap dangerouslySetInnerHTML={{ __html: paragraph }} />
			<Content>
				{subcategories.map(option => {
					return (
						<MenuContainer key={option.category}>
							<Title wrap fontSize='20px/20px' bold>
								{option.category_title}
							</Title>

							<Card
								onClick={getStep({ ...option, value: 'schedule' })}
								id={`card-${option.id}`}
								key={`card-${option.id}`}
							>
								<IconSupport src={option.icon} />
								<Label small>{option.label}</Label>
								<Arrow src={arrow} />
							</Card>
						</MenuContainer>
					)
				})}
			</Content>
		</WrapperContainer>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "fleury" }) {
			metadata {
				advice: assessoria {
					danone {
						paragraph: paragrafo

						schedule_advice: agendar_assessoria {
							subcategory: subcategoria {
								category: categoria
								category_title: titulo_categoria
								title: titulo
								id
								type
								icon: icone
								label: titulo_subcategoria
								description: paragrafo
							}
						}
					}
				}
			}
		}
	}
`

export default MedicalMenu
