import api from './api'
import { endpointsCreator } from './endpoints'

export const getSchedules = async (startDate: string, endDate: string) => {
	const endpoint = endpointsCreator()
	try {
		const url = `${endpoint.SUPPORT.GET_ADVISOR_SCHEDULES}?dateFrom=${startDate}&dateTo=${endDate}`
		const response = await api.get(url)

		if (response.status === 200 || response.status === 201) {
			return response?.data ?? []
		}
		return []
	} catch (err) {
		return err.response
	}
}

export const scheduleAdvisory = async ({
	speciality,
	holder,
	scheduled_to,
	duration,
	doctorCpf,
}: {
	speciality: string
	holder: string
	scheduled_to: string
	duration: number
	doctorCpf?: string
}) => {
	const endpoint = endpointsCreator()
	try {
		const url = endpoint.SUPPORT.SCHEDULE_ADVISORY
		const response = await api.post(url, {
			speciality,
			holder,
			scheduled_to,
			duration,
			doctorCpf,
		})

		if (response.status === 200 || response.status === 201) {
			return response
		}
	} catch (err) {
		console.log(err)
		return err
	}
}
