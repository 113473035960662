import styled from 'styled-components'

import { Span } from '../../../superSetCommons'

export const Container = styled.div`
	display: flex;
	margin: 30px 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

export const Label = styled(Span)``

export const ActionsContainer = styled.div``

export const ActionButton = styled.button<{
	negative: boolean;
}>`
	border-radius: 20px;
	padding: 10px 20px;
	margin: 10px 8px;
	border: none;
	background: ${props => (!props.negative ? props.theme.colors.red_alert : props.theme.colors.primary)};
`

export const ActionLabel = styled(Label)`
	color: #fff;
	margin-left: 5px;
`

export const ActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`
