import styled, { css } from 'styled-components'
import {
	ModalSubtitle as Subtitle,
	ModalTitle as Title,
	ModalWrapper as Wrapper,
} from '../Modal/messageModal'
import { Button as B, InputElement as IE, InputError } from '../superSetCommons'

interface Props {
	error: boolean
	formIsWrong: boolean | string
	width: string | number
	margin: string | number
}

export const ModalWrapper = styled(Wrapper)``

export const ModalTitle = styled(Title)`
	text-align: left;
`

export const ModalSubtitle = styled(Subtitle)`
	text-align: left;
`

export const FormWrapper = styled.div``

export const InputElement = styled(IE)`
	width: 100%;
`

export const SubmitButton = styled(B)<Props>`
	margin: 33px 0 0 4%;
	width: 48%;
`

export const InputWrapper = styled.div<Props>`
	display: inline-block;
	width: ${props => (props.width ? props.width : '100%')};
	text-align: left;
	margin: ${props => (props.margin ? props.margin : '14px 0 0')};
`

export const DisplayFlex = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
`

export const ContainerCheckbox = styled.div`
	display: flex;
	flex-direction: column;
	margin: 15px 0 0 0;
	text-align: left;
`

export const ConsentText = styled.p`
	text-align: left;
	font: 14px/20px CentraleSansRegular;
	letter-spacing: 0;
	color: #333333;
	width: 78%;
`

export const InputResponse = styled.span<{
	success?: boolean
}>`
	${InputError}
	text-align: justify;
	margin-left: 5px;
	color: ${props => props.theme.colors.red_alert};
	${props =>
		props.success &&
		css`
			color: ${props.theme.colors.green};
		`}
`

export const IconContainer = styled.div`
	display: flex;
	width: 165%;
	margin: 5px 0;
	flex-direction: row;
`
export const Icon = styled.img``
