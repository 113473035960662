import { String } from 'lodash'
import React from 'react'
import ReactLoading from 'react-loading'

import { InputWrapper, LoadingContainer } from './styles'

type IObject = Record<string, any>

const SemanticInput = ({
	style,
	loading,
	input: Input,
	withLoading,
	...props
}: {
	style: IObject
	props: IObject
	loading?: boolean
	withLoading?: boolean
	input: React.ReactType
}) => {
	return (
		<InputWrapper>
			<Input style={style ?? {}} {...props} />
			{withLoading && (
				<LoadingContainer>
					{loading && <ReactLoading type='spin' color='grey' height='7%' width='7%' />}
				</LoadingContainer>
			)}
		</InputWrapper>
	)
}

export default SemanticInput
