import React from 'react'

import useState from '@cuidardigital/commons/hooks/useState'

import MedicalAdvisor from './medical'
import TechSupport from './tech'

import {
	Arrow,
	Card,
	Cards,
	CloseContainer,
	Container,
	Content,
	Header,
	HeaderContent,
	Icon,
	IconSupport,
	Label,
	Overlay,
	Subtitle,
	Title,
	Top
} from './styles'

import close from '@cuidardigital/commons/assets/svg/close-icon.svg'
import arrow from '@cuidardigital/commons/assets/svg/right-arrow-icon.svg'
import { TOUR_STEP_ID } from '@cuidardigital/commons/configs/tour'
import { graphql, useStaticQuery } from 'gatsby'

const InitialStep = ({
	handleAdvance: onAdvance,
	handleToggleSupport
}: {
	handleToggleSupport: () => void
	handleAdvance: (param: string) => void
}) => {
	const data = useStaticQuery(query)
	const { title, description } = data.saudeDigital.metadata.advice
	const supports = data.saudeDigital.metadata.advice.category
	const handleAdvance = value => () => onAdvance(value)
	return (
		<Container className={TOUR_STEP_ID.SUPPORT_TECH}>
			<Header>
				<HeaderContent />
				<CloseContainer id='close-support-initial' onClick={handleToggleSupport}>
					<Icon src={close} />
				</CloseContainer>
			</Header>
			<Content>
				<Top>
					<Title fontSize='24px/24px'>{title}</Title>
					<Subtitle fontSize='14px/16px'>{description}</Subtitle>
				</Top>
				<Cards>
					{supports.map(support => {
						return (
							<Card
								id={`btn-${support.id}`}
								key={`btn-${support.id}`}
								onClick={handleAdvance(support.type)}
								className={
									support.label.toLowerCase().includes('assessoria') &&
									TOUR_STEP_ID.SUPPORT_ADVISORY
								}
							>
								<IconSupport src={support.icon} type={support.type} />

								<Label>{support.label}</Label>
								<Arrow src={arrow} />
							</Card>
						)
					})}
				</Cards>
			</Content>
		</Container>
	)
}

const Support = ({ handleToggleSupport }: { handleToggleSupport: () => void }) => {
	const [state, handleState] = useState({
		step: 'initial'
	})

	const backToInitial = () => handleState({ step: 'initial' })

	const handleAdvance = (step: string) => handleState({ step })

	const renderStep = () => {
		switch (state.step) {
			case 'support':
				return <TechSupport handleToggleSupport={handleToggleSupport} goBack={backToInitial} />
			case 'advisors':
				return <MedicalAdvisor goBack={backToInitial} handleToggleSupport={handleToggleSupport} />
			default:
				return (
					<InitialStep handleToggleSupport={handleToggleSupport} handleAdvance={handleAdvance} />
				)
		}
	}

	return <Overlay>{renderStep()}</Overlay>
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				advice: assessoria {
					title: titulo
					description: descricao

					category: categoria {
						label: titulo
						icon: icone
						description: paragrafo
						type
						id
					}
				}
			}
		}
	}
`

export default Support
