import api from './api'
import { endpointsCreator } from './endpoints'

export const verifyEligibility = async ({
	doctorCpf,
	appointmentDate,
	patientMedicalPlanNumber,
}: {
	doctorCpf: string
	appointmentDate: string
	patientMedicalPlanNumber: string
}) => {
	const endpoint = endpointsCreator()

	try {
		const response = await api.post(endpoint.ELEGIBILITY.CHECK_ELEGIBILITY, {
			appointmentDate,
			patientMedicalPlanNumber,
			doctorCpf,
		})

		return response ?? []
	} catch (err) {
		return err.response
	}
}

export const validateEligibilityId = async (preAuthNumber: string) => {
	const endpoint = endpointsCreator()
	try {
		const response = await api.post(endpoint.ELEGIBILITY.VALIDATE_ELEGIBILITY, {
			preAuthNumber,
		})

		return response.status === 200
	} catch (err) {
		console.log(err.response)
		return false
	}
}
