import React from 'react'

import { Icon } from '../../styles'
import {
	ActionButton,
	ActionContainer,
	ActionLabel,
	ActionsContainer,
	Container,
	Label
} from './styles'

import negative from '@cuidardigital/commons/assets/svg/negative-icon.svg'
import positive from '@cuidardigital/commons/assets/svg/positive-icon.svg'

const actions = [
	{ id: 'actions-yes', value: true, label: 'Sim', icon: positive },
	{ id: 'actions-no', value: false, label: 'Não', icon: negative }
]

const Footer = ({ handleAction: onAction }: { handleAction: (param: boolean) => void }) => {
	const handleAction = action => () => onAction(action)
	return (
		<Container>
			<Label>Essa resposta te ajudou ?</Label>
			<ActionsContainer>
				{actions.map((action, index) => {
					return (
						<ActionButton
							onClick={handleAction(action.value)}
							negative={action.value}
							id={`btn-${action.id}`}
							key={`btn-${action.id}`}
						>
							<ActionContainer>
								<Icon src={action.icon} />
								<ActionLabel>{action.label}</ActionLabel>
							</ActionContainer>
						</ActionButton>
					)
				})}
			</ActionsContainer>
		</Container>
	)
}

export default Footer
