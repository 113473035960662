import React from 'react'

export default function(props) {
	let color = '#748A96'
	if (typeof window !== 'undefined') {
		if (window.localStorage.getItem('marca') === 'bradesco') color = '#0b67bd'
	}
	return (
		<svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M19.8809 17.2366V18.0005C19.8809 19.6573 18.5377 21.0005 16.8809 21.0005H10.8809C9.77629 21.0005 8.88086 20.105 8.88086 19.0005C8.88086 17.8959 9.77629 17.0005 10.8809 17.0005C11.9854 17.0005 12.8809 17.8959 12.8809 19.0005H16.8809C17.4331 19.0005 17.8809 18.5528 17.8809 18.0005H16.8809C15.7763 18.0005 14.8809 17.105 14.8809 16.0005V11.0005C14.8809 9.89591 15.7763 9.00047 16.8809 9.00047H16.9649C17.2654 4.18592 15.2993 2.00047 10.822 2.00047C6.34687 2.00047 4.41665 4.18124 4.78464 9.00047H4.88086C5.98543 9.00047 6.88086 9.89591 6.88086 11.0005V16.0005C6.88086 17.105 5.98543 18.0005 4.88086 18.0005H3.88086C2.22401 18.0005 0.880859 16.6573 0.880859 15.0005V12.0005C0.880859 10.7262 1.67529 9.63754 2.79588 9.20268C2.34246 3.2242 5.10212 0.000473022 10.822 0.000473022C16.5386 0.000473022 19.3334 3.21858 18.9548 9.19842C20.0812 9.6304 20.8809 10.7221 20.8809 12.0005V15.0005C20.8809 15.889 20.4946 16.6873 19.8809 17.2366ZM2.88086 12.0005V15.0005C2.88086 15.5528 3.32857 16.0005 3.88086 16.0005H4.88086V11.0005H3.88086C3.32857 11.0005 2.88086 11.4482 2.88086 12.0005ZM16.8809 11.0005V16.0005H17.8809C18.4331 16.0005 18.8809 15.5528 18.8809 15.0005V12.0005C18.8809 11.4482 18.4331 11.0005 17.8809 11.0005H16.8809Z'
				fill='white'
			/>
		</svg>
	)
}
