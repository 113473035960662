import styled from 'styled-components'

export const Header = styled.div`
	display: flex;
	padding: 8px 0 0 10px;
	width: 100%;
	flex-direction: row;
	align-items: center;
	margin-left: -20px;
`
