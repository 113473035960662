import styled from 'styled-components'

export const InputWrapper = styled.div`
	padding: 7px 0 0px 0;
	/* margin-bottom: -10px; */
`

export const LoadingContainer = styled.div`
	position: relative;
	bottom: 2rem;
	left: 270px;
`
