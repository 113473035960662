import React from 'react'

import * as video from './styles'

import { Arrow, Card, Cards, Icon, IconContainer, IconSupport, Label } from '../../styles'

import arrow from '@cuidardigital/commons/assets/svg/right-arrow-icon.svg'

import { graphql, useStaticQuery } from 'gatsby'

interface IStep {
	type: string
	label: string
	icon: string
	color: string
	description: string
}

const Video = ({
	router
}: {
	router: {
		advance: (param: IStep) => void
	}
}) => {
	// const [state, handleState] = useState({
	// 	index: 'initial',
	// 	step: null,
	// })

	const handleAdvance = (step: IStep) => () => router.advance(step)
	const data = useStaticQuery(query)
	const supports = data.saudeDigital.metadata.advice.helpItem
	const { title_tec, icon } = data.saudeDigital.metadata.advice

	return (
		<>
			<video.Header>
				<IconContainer color='#EE3D75'>
					<Icon src={icon} />
				</IconContainer>
				<Label small>{title_tec}</Label>
			</video.Header>
			<Cards>
				{supports.map(support => {
					return (
						<>
							<Card
								id={`card-${support.id}`}
								key={`card-${support.id}`}
								onClick={handleAdvance(support)}
							>
								<IconSupport src={support.icon} />
								<Label small>{support.label}</Label>
								<Arrow src={arrow} />
							</Card>
						</>
					)
				})}
			</Cards>
		</>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				advice: assessoria {
					title_tec: titulo_tec
					icon: icone
					helpItem: item_de_ajuda {
						id
						type
						subcategory: subcategoria
						label: titulo
						description: paragrafo
						icon: icone
					}
				}
			}
		}
	}
`

export default Video
