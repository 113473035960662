import styled, { css } from 'styled-components'

import { PrimaryButton as CommonButton, Span } from '../../../superSetCommons'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

export const Description = styled.span`
	padding: 15px 0;
	text-align: center;
`

export const ButtonContainer = styled.div``

export const Button = styled(CommonButton)<{
	cancel?: boolean
}>`
	margin-top: 30px;
	${props =>
		props.cancel &&
		css`
			color: ${props => props.theme.colors.secundary};
			background: ${props => props.theme.colors.white};
			border: 1px solid ${props => props.theme.colors.secundary_border};
			&:hover {
				background: ${props => props.theme.colors.secundary_hover};
				color: ${props => props.theme.colors.white};
			}
		`}
`

export const Text = styled.span`
	font-weight: bold;
`

export const MetaData = styled.div`
	/* padding: 0px 0 30px 0px; */
	margin-bottom: 30px;
	margin-top: 10px;
	/* margin: 20px 0; */
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const MetaTitle = styled(Span)``

export const MetaDescription = styled(Span)``
