import styled from 'styled-components'
import { PrimaryButton } from '../../../superSetCommons'
import { IconContainer } from '../../styles'

export const ScheduleButton = styled(PrimaryButton)``

export const CalendarContainer = styled.div`
	padding: 30px 0;
`
export const Header = styled.div`
	display: flex;
	flex-direction: column;
`

export const HeaderContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 10px;
`

export const IconWrapper = styled(IconContainer)`
	margin-left: 0;
`
