// import styled from 'styled-components'

// import {
// 	Button as SuperButton,
// 	ButtonLink as SuperButtonLink,
// 	Span as SuperSpan,
// 	SpanDisabled,
// 	SpanHighlight
// } from '../../superSetCommons'

// import { Col as CommonColumn, Grid as CommonGrid } from '@cuidardigital/commons/components/Grid/grid'

// export const Col = styled(CommonColumn)``
// export const Grid = styled(CommonGrid)``

// interface IWrapper {
// 	height?: string | number
// 	mt?: string | number
// 	bg?: string
// }

// export const Span = styled(SuperSpan)``

// export const PatientLinkWrapper = styled(SpanHighlight)`
// 	cursor: pointer;
// 	text-align: left;
// 	-webkit-text-decoration: underline;
// 	text-decoration: underline;
// 	-webkit-letter-spacing: 0;
// 	-moz-letter-spacing: 0;
// 	-ms-letter-spacing: 0;
// 	letter-spacing: 0;
// 	width: min-content;
// `

// export const ButtonLink = styled(SuperButtonLink)`
// 	color: ${props => props.theme.colors.primary_hover};
// `

// export const ComponentWrapper = styled(CommonGrid)`
// 	// height: 260px;
// 	background: #ffffff 0% 0% no-repeat padding-box;
// 	box-shadow: 0px 0px 10px #00000026;
// 	border-radius: 8px;
// 	opacity: 1;
// 	padding: 20px;
// 	padding-bottom: 10px;
// 	grid-row-gap: 0;
// `

// export const Divider = styled.hr`
// 	opacity: 0.2;
// `

// export const NewLink = styled.a`
// 	cursor: pointer;
// 	text-align: left;
// 	text-decoration: underline;
// 	font: Bold 14px/20px CentraleSans-Bold;
// 	letter-spacing: 0;
// 	color: ${props => props.theme.colors.primary_hover};

// 	@media (max-width: 1340px) {
// 		font: Bold 10px/20px CentraleSans-Bold;
// 	}

// 	@media (max-width: 768px) {
// 		font: Bold 8px/20px CentraleSans-Bold;
// 	}
// `

// export const Button = styled(SuperButton)`
// 	margin: 20px 0 0;
// `

// // closed
// export const ButtonContainer = styled.div`
// 	padding-top: 7px;
// 	padding-bottom: 5px;
// 	max-width: 80%;
// `
// export const ClosedComponentWrapper = styled(CommonGrid)<IWrapper>`
// 	background: ${({ bg }) => bg || '#ffffff'} 0% 0% no-repeat padding-box;
// 	box-shadow: 0px 0px 10px #00000026;
// 	border-radius: 8px;
// 	opacity: 1;
// 	padding: 22px;
// 	grid-row-gap: 0;
// `

// export const EmailSpan = styled(SpanDisabled)`
// 	display: block;
// 	white-space: inherit;
// 	width: 100%;

// 	padding-bottom: 20px;
// `

// export const ClosedButton = styled(SuperButton)`
// 	border: 1px solid ${props => props.theme.colors.secundary_border};
// 	color: ${props => props.theme.colors.font_secundary};
// 	background: ${props => props.theme.colors.secundary};
// 	&:hover {
// 		background: ${props => props.theme.colors.secundary_hover};
// 	}
// `

import styled from 'styled-components'

const Flex = styled.div`
	display: flex;
`

export const Row = styled(Flex)<{
	ml?: number
	mr?: number
	mt?: number
	mb?: number
	alignCenter?: boolean
	justifyCenter?: boolean
}>`
	flex: 1;
	width: 100%;
	flex-direction: row;
	${({ alignCenter }) => alignCenter && 'align-items: center;'}
	${({ justifyCenter }) => justifyCenter && 'justify-content: center;'}
	margin-right: ${({ mr }) => mr ?? 0}px;
	margin-left: ${({ ml }) => ml ?? 0}px;
	margin-bottom: ${({ mb }) => mb ?? 0}px;
	margin-top: ${({ mt }) => mt ?? 0}px;
`

export const Column = styled(Flex)<{
	ml?: number
	mr?: number
	mt?: number
	mb?: number
	alignCenter?: boolean
	justifyCenter?: boolean
}>`
	${({ alignCenter }) => alignCenter && 'align-items: center;'}
	${({ justifyCenter }) => justifyCenter && 'justify-content: center;'}
	margin-right: ${({ mr }) => mr ?? 0}px;
	margin-left: ${({ ml }) => ml ?? 0}px;
	margin-bottom: ${({ mb }) => mb ?? 0}px;
	margin-top: ${({ mt }) => mt ?? 0}px;
	flex-direction: column;
`

export const WrapperContainer = styled(Column)`
	padding: 20px 15px;
	background: ${({ bg, theme }) => (bg && theme.colors[bg]) || '#ffffff'} 0% 0% no-repeat
		padding-box;
	box-shadow: 0px 0px 13px #ccc;
	border-radius: 8px;
`

export const DividerWrapper = styled.div``

export const Divider = styled.hr`
	opacity: 0.2;
`

export const Badge = styled(Row)`
	padding: 8px;
	margin-right: 10px;
	border-radius: 4px;
	background: ${({ theme }) => theme.colors.gray10};
`

export const Button = styled.button`
	background: none;
	border: none;
	cursor: pointer;
`
