import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const CopyClipboard = ({ patientLink, children, onCopy }) => {
	return (
		<>
			{patientLink && (
				<CopyToClipboard text={patientLink} onCopy={onCopy}>
					{children}
				</CopyToClipboard>
			)}
		</>
	)
}

export default CopyClipboard
