import styled from 'styled-components'

import { Span } from '../superSetCommons'

export const Overlay = styled.div`
	display: flex;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);

	@media (max-width: 767.98px) {
		padding-top: 100px;
	}
`

export const Container = styled.div`
	width: 400px;
	max-height: 76vh;
	overflow: auto;
	background: #fff;
	border-radius: 8px;
	position: absolute;
	right: 55px;
	bottom: 13%;
	display: flex;
	flex-direction: column;
`

export const Cards = styled.div`
	padding: 12px 0;
`

export const Top = styled.div`
	display: flex;
	flex-direction: column;
`

export const Title = styled(Span)<{
	success?: boolean
}>`
	text-align: left;
	line-height: 26px;
	font-weight: 200;
	white-space: break-spaces;
	color: ${props => (props.success ? props.theme.colors.primary : props.theme.colors.red_alert)};
`

export const Subtitle = styled(Span)`
	margin: 8px 0 0 0;
	/* word-wrap: break-all; */
	color: ${props => props.theme.colors.font_dark};
`

export const Content = styled.div`
	padding: 20px 32px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`

export const Card = styled.button`
	width: 100%;
	margin: 15px 0;
	background: #f0f4f5;
	border: none;
	padding: 10px 0;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`

export const IconContainer = styled.div<{
	color?: string
}>`
	//background: ${props => props.color || '#748A96'};
	padding: 5px 15px;
	border-radius: 4px;
	margin-left: 10px;
	display: flex;
`

export const Icon = styled.img``

export const IconSupport = styled.img`
	padding: 5px 15px;
	border-radius: 4px;
	margin-left: 10px;
	display: flex;
`

export const Arrow = styled.img`
	margin-right: 10px;
`

export const Label = styled.span<{
	small?: boolean
}>`
	width: 100%;
	text-align: left;
	color: #748a96;
	font-weight: bold;
	word-wrap: break-all;
	font-size: ${props => (props.small ? '14px' : '16px')};
`

export const CardContent = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
`

export const CloseContainer = styled.button`
	display: flex;
	flex-direction: row;
	border: none;
	background: none;
	width: 50%;
	margin: 0 10px;
	justify-content: flex-end;
`

export const BackContainer = styled.button`
	border: none;
	background: none;
	margin-right: 5px;
`

export const Header = styled.div`
	display: flex;
	padding: 10px 5px 0 20px;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

export const HeaderContent = styled.div<{
	end?: boolean
	success?: boolean
}>`
	display: flex;
	padding: ${props => (props.success ? '10px 0 0 10px' : '10px 0 0 0')};
	flex-direction: row;
	align-items: center;
	justify-content: ${props => (props.end ? 'flex-end' : 'flex-start')};
	width: 100%;
`

export const TitleTech = styled(Span)<{
	success?: boolean
}>`
	text-align: left;
	line-height: 26px;
	font-weight: 200;
	color: ${props => (props.success ? props.theme.colors.green : props.theme.colors.red_alert)};
`
